export const tags = ['Blog']

// TODO - Is article meta going to pull article data as well?
export const siteName = "White Owl Education"

export const blogPost = {
    id: "2021-05-07-00",
    created_at: "2021-05-07T00:00:00.000-00:00",
    updated_at: "2021-05-07T00:00:00.000-00:00",
    page: {
        url: "https://www.whiteowleducation.com/best-practices-to-become-a-data-engineer/"
    },
    author: {
        type: "Person",
        name: "White Owl Education",
        author_image_url: "https://whiteowleducation.com/images/authors/white-owl-education/white-owl-education-author-face.jpg",
        pathname: "images/authors/white-owl-education/white-owl-education-author-face.jpg",
        author_image_description: "Picture of Ralph Brooks, CEO of White Owl Education"
        
    },
    title: "Best Practices to Become a Data Engineer",
    publisher: {
        name: siteName,
        url: "https://whiteowleducation.com/images/theme/header/logo/wo-logo-transparent.png"
    },
    description: "Approaches to set up your environment, to learn useful programming languages, and to build pipelines.",
    image: {
        url: "https://whiteowleducation.com/images/blog/2021/05/07/card-main-image.jpg",
        description: "Data engineer looking at a whiteboard."
    },
    internal_featured_image: {
        pathname: "images/blog/2021/05/07/whiteboard.jpg"
    },
    keywords: tags[0],
    featured: "no",
    read_time_minutes : "10",
    visibility: "public"
}